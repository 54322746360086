import React, { Component } from 'react';
import earth from './images/earth-night.jpg';
import {animateScroll as scroll} from 'react-scroll';
import './App.css';

const translations = {
  "en": {
    "welcome" : "Hi, I'm Abdullah, I make software."
  }
}

const imageStyle = {
  backgroundImage: "url(" + earth + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      lang : "en"
    };
  }

  render() {
    return (
      <div className="app" dir={ this.state.lang=="en" ? "ltr" : "rtl" } lang={this.state.lang}>
        <div className="welcome" style={imageStyle}>
          <div className="effect"></div>
          <div className="content">
            <h1>{ translations[this.state.lang].welcome } </h1>
          </div>
          <div className='footer' to="info"><a href="https://github.com/AbdullahAleiti"><i className="github" style={{fontSize:"2em"}} class="fab fa-github"></i></a></div>
        </div>
      </div>
    );
  }

  changeLang = (props) => {
    this.setState({lang : props});
    scroll.scrollToTop();
  }

}

export default App;
